import React from 'react';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { graphql } from 'gatsby';
import { Layout, Logo } from 'components';
import { transformSnakeKeysToCamel } from '../utils';
import { Slice } from '../slices/Slice';
import * as styles from './landingPage.module.scss';
import { LandingPageContext } from '../context/LandingPageContext';

const LandingPage = ({ location, data: staticData }) => {
  const { prismicLandingPage, settings } = staticData;
  const { prismicId, uid } = prismicLandingPage;
  const pageData = transformSnakeKeysToCamel(prismicLandingPage.data);
  const {
    body: sliceData,
    companyName,
    primaryColour,
    secondaryColour,
    backgroundColour,
    textColour,
    platformLogo,
    isEoi,
    isNewsletterCta,
    isVersionOne,
    isSyndicateDeal,
    isWholesaleDeal,
  } = pageData;

  return (
    <LandingPageContext.Provider
      value={{
        prismicId,
        uid,
        companyName,
        primaryColour,
        secondaryColour,
        backgroundColour,
        textColour,
        platformLogo,
        isEoi,
        settings,
        isVersionOne,
        isSyndicateDeal,
        isWholesaleDeal,
      }}
    >
      <Layout
        location={location}
        pageData={{ ...pageData, defaultMetaTitle: `${pageData.companyName.text} | Equitise` }}
        bodyClassName={styles.landingPage}
        hideCommonUi={!isNewsletterCta && !isSyndicateDeal}
        isNewsletterCta
        platformLogo={platformLogo}
        // inheritedClassnames={layoutClassnames}
      >
        {!isNewsletterCta && !isSyndicateDeal && (
          <div className={styles.logoHeader}>
            <div className={styles.logoContainer}>
              <Logo type="tertiaryLight" inheritedClassname={styles.logo} />
            </div>
          </div>
        )}
        <section className="landing-page">
          <div className={styles.wrapper}>
            <section className={styles.landingPageBody} id="landing-page-body">
              <section>
                {sliceData?.map(
                  slice =>
                    slice.id &&
                    slice.slice_type && <Slice key={slice.id} data={slice} location={location} template="landingPage" />
                )}
              </section>
            </section>
          </div>
        </section>
      </Layout>
    </LandingPageContext.Provider>
  );
};

export default withPrismicPreview(LandingPage);

export const pageQuery = graphql`
  query LandingPageBySlug($uid: String!) {
    prismicLandingPage(uid: { eq: $uid }) {
      id
      uid
      prismicId
      _previewable
      data {
        company_name {
          text
        }
        hide_navigation_bar
        hide_on_production
        visibility
        primary_colour
        secondary_colour
        background_colour
        text_colour
        platform_logo {
          url
        }
        body {
          ...CtaRegisterInterestFormLandingPageFragment
          ...CtaImageVideoLandingPageFragment
          ...CtaVideoAndRegisterInterestFormLandingPageFragment
          ...CtaDisclaimerLandingPageFragment
          ...CtaLandingPageFragment
          ...CtaImageTextLandingPageFragment
          ...DocumentsLadingPageFragment
        }
        meta_description {
          text
        }
        meta_title {
          text
        }
        open_graph_image {
          url
        }
        schema {
          text
        }
        is_eoi
        is_newsletter_cta
        is_version_one
        is_syndicate_deal
        is_wholesale_deal
      }
    }
    ...WarningStatementsFragment
  }
`;
